<template>
  <div class="route">
    <h4 @click="$emit('click')">
      <span class="city"> {{ lead.pickupLocation.city }}</span
      >, <span class="upper">{{ lead.pickupLocation.state }}</span> ({{ lead.pickupLocation.zip }})
      <i class="fas fa-arrow-right"></i>
      <span class="city">{{ lead.deliveryLocation.city }}</span
      >, <span class="upper">{{ lead.deliveryLocation.state }}</span> ({{ lead.deliveryLocation.zip }})
    </h4>

    <b-button-group size="sm" class="my-1">
      <a title="25 Miles Around" class="btn border-left-pill btn-secondary" target="_blank" rel="noreferrer noopener" :href="getCDLink(25)">
        <i class="fas fa-angle-right"></i>
      </a>
      <a title="50 Miles Around" class="btn btn-secondary" target="_blank" rel="noreferrer noopener" :href="getCDLink(50)">
        <i class="fas fa-angle-double-right"></i>
      </a>
      <a title="100 Miles Around" class="btn border-left-pill btn-secondary" target="_blank" rel="noreferrer noopener" :href="getCDLink(100)">
        <i class="fas fa-angle-right"></i>
        <i style="margin-left: -0.28rem" class="fas fa-angle-double-right"></i>
      </a>
    </b-button-group>
  </div>
</template>

<script>
export default {
  name: 'LeadsCardRoute',
  props: {
    lead: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    getCDLink(distance) {
      let url = 'https://app.centraldispatch.com/search?search='+
        '~(vC~(mn~1)~tLPW~2~opt~%27all~ofst~0~lmt~50~sF~(~(n~%27pup~d~%27ASC)~(n~%27dMA~d~%27ASC))~dBS~false~sPSO~false~sTOT~false~rT~%27Open~l~(~(c~%27'+
        this.lead.pickupLocation.city?.replace(' ','*20')+
        '~st~%27'+
        this.lead.pickupLocation.state +
        //'~zC~%27'+
        //this.lead.pickupLocation.zip +
        '~r~' +
        distance +
        '~s~%27Pickup)~(c~%27'+
        this.lead.deliveryLocation.city?.replace(' ','*20')+
        '~st~%27'+
        this.lead.deliveryLocation.state +
        //'~zC~%27' +
        //this.lead.deliveryLocation.zip +
        '~r~' +
        distance +
        '~s~%27Dropoff)))';
      return url;
    },
  },
};
</script>

<style lang="scss" scoped>
.route {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.upper {
  text-transform: uppercase;
}

h4 {
  margin: 0 16px 0 0;
  font-weight: normal;
  cursor: pointer;
}

.city {
  text-transform: capitalize;
  font-weight: bold;
}
</style>
