<template>
  <div>
    <h5>Source Sales Report</h5>

    <b-card class="mb-4">
      <b-form inline @submit.prevent="submit">
        <b-form-radio v-model="periodType" name="periodType" value="list">Time period</b-form-radio>
        <b-select class="custom-select ml-3" v-if="periodType == 'list'" v-model="timePeriod" :options="timeOptions" />
        <span class="ml-3">OR</span>
        <b-form-radio v-model="periodType" name="periodType" value="custom" class="ml-3">Custom date range</b-form-radio>
        <flat-pickr class="form-control ml-3" v-if="periodType != 'list'" v-model="selectedDates" :config="fltConfig" />

        <b-select class="custom-select ml-5" v-model="filterType" :options="dataOptions" />

        <b-select class="custom-select ml-5" v-model="filter" :options="filterOptions" />

        <button class="btn btn-primary ml-3">Show Report</button>
      </b-form>
    </b-card>

    <LoadingIndicator v-if="loading" centered />

    <span v-if="!this.items.length && !totals.length">No records found</span>

    <b-card class="mb-4" v-if="!loading && (items.length || totals.length)">

      <b-table striped hover :items="totals" :fields="fields2" caption-top>
      </b-table>

      <b-table striped hover :items="items" :fields="fields" caption-top>
        <template #table-caption>
          <span v-if="form.createdStart">Created from {{ form.createdStart }} till {{ form.createdEnd }}</span>
        </template>
        <template #cell(leadId)="data">
          <b-link :to="orderLink(data.item)" target="_blank">{{ data.value }}</b-link>
        </template>
        <template #cell(userId)="data">
          <b-link v-if="data.value" :to="userLink(data.value)" target="_blank">{{ data.item.user ? data.item.user.name : '' }}</b-link>
        </template>
        <template #cell(dateReceived)="data">
          {{ (data.item.dateReceived || data.item.createdAt) | formatDate }}
        </template>
        <template #cell(paymentTypeId)="data">
          {{ paymentTypes[data.value] ? paymentTypes[data.value].title : '' }}
        </template>
        <template #cell(paymentDirectionId)="data">
          {{ paymentDirections[data.value] ? paymentDirections[data.value].title : '' }}
        </template>
        
        <template #cell(show_details)="row">
          <b-btn size="xs" variant="secondary" @click.prevent="toggleDetails(row)">
            {{ row.detailsShowing ? 'Hide' : 'Zoom' }}
          </b-btn>
        </template>
        <template #row-details="row">
          <b-card>
            <LoadingIndicator v-if="row.item.loading" centered />
            <b-table v-if="row.item.rows.length" striped hover :items="row.item.rows" :fields="detailsFields" :tbody-tr-class="rowClass" class="mb-0">
              <template #cell(id)="data">
                <b-link :to="orderLink(data.item)" target="_blank">{{ data.value }}</b-link>
              </template>
            </b-table>
          </b-card>
        </template>
      </b-table>

      
    </b-card>
  </div>
</template>

<script>
import { tableFields } from '@/mixins/methods';
import { ApiClient } from '@/api/ApiClient';
import LoadingIndicator from '@/components/shared/LoadingIndicator';
import permissionsMixin from '/src/mixins/permissions';
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment';

export default {
  name: 'ReportPayments',
  data() {
    return {
      loading: false,
      items: [],
      paymentTypes: {},
      paymentDirections: {},
      filter: null,
      totals: [],
      periodType: 'list',
      filterType: 1,
      timePeriod: 1,
      selectedDates: null,
      filterOptions: [
        { text: 'Show only totals', value: null },
        { text: 'Show list of payments', value: 1 },
      ],

      form: {
        createdStart: null,
        createdEnd: null,
        dispatchedStart: null,
        dispatchedEnd: null,
        filter: null,
      },
      fltConfig: {
        mode: 'range',
        dateFormat: 'Y-m-d',
        altInput: true,
        altFormat: 'm/d/Y',
        allowInput: true,
      },
      timeOptions: [
        { text: 'Current month', value: 1 },
        { text: 'Last month', value: 2 },
        { text: 'Current quarter', value: 3 },
        { text: 'Last quarter', value: 4 },
        { text: 'Current year', value: 5 },
        { text: 'All time', value: 6 },
      ],
      dataOptions: [
        { text: 'Created payments - during time frame', value: 1 },
      ],
      fields: [
        {
          key: 'leadId',
          label: 'Order',
          sortable: false,
        },
        {
          key: 'dateReceived',
          label: 'Date',
          sortable: false,
        },
        {
          key: 'paymentTypeId',
          label: 'Type',
          sortable: false,
        },
        {
          key: 'paymentDirectionId',
          label: 'Direction',
          sortable: false,
        },
        {
          key: 'amount',
          label: 'Amount',
          sortable: false,
        },
        {
          key: 'surcharge',
          label: 'Surcharge',
          sortable: false,
        },
        {
          key: 'userId',
          label: 'User',
          sortable: false,
        },  
/*        
        {
          key: 'show_details',
          label: '',
          sortable: false,
        },
*/
      ],
      fields2: [
        {
          key: 'title',
          label: 'Payment Direction',
          sortable: false,
        },
        {
          key: 'totalAmount',
          label: 'Total Amount',
          sortable: false,
        },
        {
          key: 'totalSurcharge',
          label: 'Total Surcharge',
          sortable: false,
        },
      ],
      detailsFields: [
        {
          key: 'id',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'type',
          label: 'Type',
          sortable: true,
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'tarrifs',
          label: 'Tarrif',
          sortable: true,
        },
        {
          key: 'customerPaidCompany',
          label: 'Deposit paid',
          sortable: true,
        },
        {
          key: 'carrierPay',
          label: 'Carrier pay',
          sortable: true,
        },
        {
          key: 'pendingDeposit',
          label: 'Unpaid deposit',
          sortable: true,
        },
        {
          key: 'signedAt',
          label: 'Dispatched At',
          sortable: true,
        },
      ],
    };
  },
  mixins: [tableFields, permissionsMixin],
  components: {
    LoadingIndicator,
    flatPickr,
  },
  methods: {
    async submit(e) {
      e.preventDefault();
      this.fetchPayments();
    },
    toggleDetails(row) {
      row.toggleDetails();
      if (row.item._showDetails) {
        //this.fetchPaymentsDetails(row.item);
      }
    },
    async fetchPayments() {
      this.prepareDates();
      this.loading = true;
      this.form.filter = this.filter;
      const response = await ApiClient.reports.payments({ params: this.form });
      this.loading = false;
      this.items = response.data.data.rows.map(x => {
        x.rows = [];
        x.loading = false;
        return x;
      });
      this.paymentDirections = response.data.data.paymentDirections.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});
      this.paymentTypes = response.data.data.paymentTypes.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});

      this.totals = response.data.data.totals;
    },
    userLink(id) {
      return id ? { name: 'UserDetails', params: { id: id } } : {};
    },
    orderLink(item) {
      if(!item.lead) return null;
      if (item.lead.leadTypeId == 3) return { name: 'OrderDetails', params: { id: item.lead.id } };
      if (item.lead.leadTypeId == 2) return { name: 'QuoteDetails', params: { id: item.lead.id } };
      if (item.lead.leadTypeId == 1) return { name: 'LeadDetails', params: { id: item.lead.id } };
      return null;
    },
    prepareDates() {
      let parts = [];
      if (this.periodType == 'list') {
        if (this.timePeriod == 1) {
          parts.push(moment().startOf('month').format('YYYY-MM-DD'));
          parts.push(moment().endOf('month').format('YYYY-MM-DD'));
        }
        if (this.timePeriod == 2) {
          parts.push(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
          parts.push(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
        }
        if (this.timePeriod == 3) {
          parts.push(moment().startOf('quarter').format('YYYY-MM-DD'));
          parts.push(moment().endOf('quarter').format('YYYY-MM-DD'));
        }
        if (this.timePeriod == 4) {
          parts.push(moment().subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD'));
          parts.push(moment().subtract(1, 'quarter').endOf('quarter').format('YYYY-MM-DD'));
        }
        if (this.timePeriod == 5) {
          parts.push(moment().startOf('year').format('YYYY-MM-DD'));
          parts.push(moment().endOf('year').format('YYYY-MM-DD'));
        }
        if (this.timePeriod == 6) {
          parts.push(null);
          parts.push(null);
        }
      } else {
        if (!this.selectedDates) return;
        parts = this.selectedDates.split(' to ');
        if (parts.length < 2) parts.push(parts[0]);
      }

      this.form.createdStart = null;
      this.form.createdEnd = null;
      this.form.dispatchedStart = null;
      this.form.dispatchedEnd = null;

      if (this.filterType == 1) {
        this.form.createdStart = parts[0];
        this.form.createdEnd = parts[1];
      } else {
        this.form.dispatchedStart = parts[0];
        this.form.dispatchedEnd = parts[1];
      }
    },
    rowClass(item, type) {
      if (type === 'row' && item && item.tasksExists) return 'text-danger';
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-danger a {
  color: inherit;
}
</style>
