//import LayoutCentered from '@/layout/LayoutCentered';
import LayoutFull from '@/layout/LayoutFull';
import ReportList from '@/components/pages/reports/ReportList';
import ReportSales from '@/components/pages/reports/ReportSales';
import ReportDispatchSales from '@/components/pages/reports/ReportDispatchSales';
import BalanceIssues from '@/components/pages/reports/BalanceIssues';
import AccountsPayable from '@/components/pages/reports/AccountsPayable';
import TopPosters from '@/components/pages/reports/TopPosters';
import ReportCompetition from '@/components/pages/reports/ReportCompetition';
import ReportLeads from '@/components/pages/reports/ReportLeads';
import ReportFollowups from '@/components/pages/reports/ReportFollowups';
import ReportCancellation from '@/components/pages/reports/ReportCancellation';
import ReportAgentSourceSales from '@/components/pages/reports/ReportAgentSourceSales';
import ReportOrders from '@/components/pages/reports/ReportOrders';
import ReportPayments from '@/components/pages/reports/ReportPayments';

export default [
  {
    path: '/reports',
    component: LayoutFull,
    meta: {
      auth: true,
    },
    children: [
      {
        path: '',
        name: 'ReportList',
        component: ReportList,
      },
      { path: 'orders', name: 'ReportOrders', component: ReportOrders },
      { path: 'sales', name: 'ReportSales', component: ReportSales },
      { path: 'dispatch', name: 'ReportDispatchSales', component: ReportDispatchSales },
      { path: 'top-posters', name: 'ReportTopPosters', component: TopPosters },
      { path: 'leads', name: 'ReportLeads', component: ReportLeads },
      { path: 'competition-report', name: 'ReportCompetition', component: ReportCompetition },
      { path: 'followups', name: 'ReportFollowups', component: ReportFollowups },
      { path: 'cancellation', name: 'ReportCancellation', component: ReportCancellation },
      { path: 'balance-issues', name: 'ReportBalanceIssues', component: BalanceIssues },
      { path: 'accounts-payable', name: 'ReportAccountsPayable', component: AccountsPayable },
      { path: 'agent-source-sales', name: 'ReportAgentSourceSales', component: ReportAgentSourceSales },
      { path: 'payments', name: 'ReportPayments', component: ReportPayments },
    ],
  },
];
