const locationTypes = [
  { value: '1', label: 'Residence' },
  { value: '2', label: 'Business' },
  { value: '3', label: 'Auction' },
  { value: '4', label: 'Parking Lot' },
  { value: '5', label: 'Hotel' },
  { value: '6', label: 'Dealership' },
  { value: '7', label: 'Rental Agency' },
];

export default {
  data() {
    return {
      locationTypes,
    };
  },
  methods: {
    locationTypeLabel(value) {
      if (!value) {
        return '-';
      }
      return locationTypes.find(dt => dt.value == value).label;
    },
  },
};
