<template>
  <div>
    <h5>Accounts Payable/Receivable</h5>

    <b-card class="mb-4">
      <b-form inline @submit.prevent="submit">
        <b-select class="custom-select ml-5" v-model="filter" :options="dataOptions" />
        <button class="btn btn-primary ml-3">Show Report</button>
      </b-form>
    </b-card>

    <LoadingIndicator v-if="loading" centered />

    <span v-if="empty">No records found</span>

    <b-card class="mb-4" v-if="!loading && items.length">
      <b-table striped hover :items="items" :fields="fields" caption-top>
        <template #table-caption>
          <span v-if="form.dispatchedStart">Dispatched from {{ form.dispatchedStart }} till {{ form.dispatchedEnd }}</span>
          <span v-if="form.createdStart">Created from {{ form.createdStart }} till {{ form.createdEnd }}</span>
        </template>
        <template #cell(userId)="data">
          <b-link v-if="data.value" :to="userLink(data.value)" target="_blank">{{ data.item.user ? data.item.user.name : '' }}</b-link>
        </template>
        <template #cell(id)="data">
          <b-link :to="orderLink(data.item)" target="_blank">{{ data.value }}</b-link>
        </template>
        <template #cell(balanceMethodId)="data">
          {{ paymentTypeLabel(data.value) }}
        </template>

        <template #custom-foot="data">
          <b-tr>
            <b-th v-for="field in data.fields" :key="field.key">{{ field.key == 'id' ? 'Totals' : totals[field.key] || '' }}</b-th>
          </b-tr>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { tableFields } from '@/mixins/methods';
import { ApiClient } from '@/api/ApiClient';
import LoadingIndicator from '@/components/shared/LoadingIndicator';
import permissionsMixin from '/src/mixins/permissions';
import balanceMethodsMixin from '@/mixins/balanceMethods';

export default {
  name: 'AccountsPayable',
  data() {
    return {
      loading: false,
      empty: false,
      items: [],
      totals: {},
      filter: null,
      form: {
        createdStart: null,
        createdEnd: null,
        dispatchedStart: null,
        dispatchedEnd: null,
        filter: null,
      },
      dataOptions: [
        { text: 'All Orders', value: null },
        { text: 'Retail Orders', value: 1 },
        { text: 'Dealer Orders', value: 2 },
      ],
      fields: [
        {
          key: 'id',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'userId',
          label: 'User',
          sortable: false,
        },
        {
          key: 'source',
          label: 'Source',
          sortable: true,
        },/*
        {
          key: 'type',
          label: 'Type',
          sortable: true,
        },*/
        {
          key: 'status',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'tarrifs',
          label: 'Tarrif',
          sortable: true,
        },
        {
          key: 'deposit',
          label: 'Deposit',
          sortable: true,
        },
        {
          key: 'carrierPay',
          label: 'Carrier pay',
          sortable: true,
        },
        {
          key: 'brokerFee',
          label: 'Broker Fee',
          sortable: true,
        },
        {
          key: 'customerPaidCompany',
          label: 'Deposit paid',
          sortable: true,
        },
        {
          key: 'pendingDeposit',
          label: 'Unpaid deposit',
          sortable: true,
        },
        {
          key: 'carrierOwesCompany',
          label: 'Carrier owes Nexus',
          sortable: true,
        },
        {
          key: 'companyOwesCarrier',
          label: 'Nexus owes Carrier',
          sortable: true,
        },
        {
          key: 'balanceMethodId',
          label: 'Balance method',
          sortable: true,
        },
        {
          key: 'signedAt',
          label: 'Dispatched At',
          sortable: true,
        },
      ],
    };
  },
  mixins: [tableFields, permissionsMixin, balanceMethodsMixin],
  components: {
    LoadingIndicator,
  },
  methods: {
    async submit(e) {
      e.preventDefault();
      this.fetchUnpaidOrders();
    },
    async fetchUnpaidOrders() {
      this.loading = true;
      this.form.filter = this.filter;
      const response = await ApiClient.reports.accountsPayable({ params: this.form });
      this.loading = false;
      this.items = response.data.data.rows;
      this.totals = response.data.data.totals;
    },
    userLink(id) {
      return id ? { name: 'UserDetails', params: { id: id } } : {};
    },
    orderLink(item) {
      if (item.leadTypeId == 3) return { name: 'OrderDetails', params: { id: item.id } };
      if (item.leadTypeId == 2) return { name: 'QuoteDetails', params: { id: item.id } };
      if (item.leadTypeId == 1) return { name: 'LeadDetails', params: { id: item.id } };
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-danger a {
  color: inherit;
}
</style>
